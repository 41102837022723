<template>
  <main class="main profile-page page-inside" v-if="$parent.isAuth">
    <div class="main-wrapper">
      <div class="title title-top">
        <img src="./../image/hero.jpg" class="bg"/>
        <b>{{ $t('Profile') }}</b>
      </div>
      <div class="section hero-section">
        <div class="page-inside-wrapper">
          <div class="nav-container">
            <div class="nav">
              <router-link 
                to="/profile/settings" 
                class="item"
              >
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.8736 6.19822C22.661 5.8365 22.3584 5.536 21.9952 5.32601C21.632 5.11602 21.2205 5.00371 20.801 5H11.199C10.7795 5.00371 10.368 5.11602 10.0048 5.32601C9.64159 5.536 9.33896 5.8365 9.1264 6.19822L4.3254 14.5129C4.11223 14.8822 4 15.301 4 15.7274C4 16.1537 4.11223 16.5725 4.3254 16.9418L9.1264 25.2241C9.33896 25.5858 9.64159 25.8863 10.0048 26.0963C10.368 26.3063 10.7795 26.4186 11.199 26.4223H20.801C21.2205 26.4186 21.632 26.3063 21.9952 26.0963C22.3584 25.8863 22.661 25.5858 22.8736 25.2241L27.6746 16.9094C27.8878 16.5402 28 16.1213 28 15.695C28 15.2686 27.8878 14.8498 27.6746 14.4805L22.8736 6.19822ZM20.0481 15.7112C20.0481 16.5118 19.8106 17.2944 19.3658 17.9601C18.921 18.6258 18.2888 19.1447 17.5491 19.4511C16.8094 19.7575 15.9955 19.8376 15.2103 19.6814C14.425 19.5252 13.7037 19.1397 13.1376 18.5736C12.5715 18.0074 12.1859 17.2861 12.0297 16.5009C11.8735 15.7157 11.9537 14.9017 12.2601 14.162C12.5665 13.4223 13.0853 12.7901 13.751 12.3453C14.4167 11.9005 15.1994 11.6631 16 11.6631C17.0736 11.6631 18.1033 12.0896 18.8624 12.8488C19.6216 13.6079 20.0481 14.6375 20.0481 15.7112Z" fill="#72738C"/>
                </svg>
                <div class="desc">
                  <b>{{ $t('Settings') }}</b>
                </div>
              </router-link>
              <router-link 
                to="/profile/orders" 
                class="item"
              >
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.75797 7.23901H26.6009C27.0006 7.23901 27.3812 7.4103 27.6465 7.70809C27.9119 8.00701 28.0373 8.40556 27.9902 8.80187L26.9961 17.2531C26.9211 17.8969 26.4117 18.4051 25.7669 18.479L11.6531 20.1079C11.2344 20.1561 10.8448 19.8862 10.7429 19.4776L7.94408 8.28241C7.88139 8.03164 7.93737 7.76519 8.09634 7.56144C8.25531 7.35768 8.49937 7.23901 8.75797 7.23901ZM21.9124 11.4372V14.7958C21.9124 15.2593 22.2885 15.6354 22.752 15.6354C23.2155 15.6354 23.5916 15.2593 23.5916 14.7958V11.4372C23.5916 10.9737 23.2155 10.5976 22.752 10.5976C22.2885 10.5976 21.9124 10.9737 21.9124 11.4372ZM17.4343 11.4372V14.7958C17.4343 15.2593 17.8104 15.6354 18.2739 15.6354C18.7374 15.6354 19.1136 15.2593 19.1136 14.7958V11.4372C19.1136 10.9737 18.7374 10.5976 18.2739 10.5976C17.8104 10.5976 17.4343 10.9737 17.4343 11.4372ZM12.9562 11.4372V14.7958C12.9562 15.2593 13.3323 15.6354 13.7958 15.6354C14.2593 15.6354 14.6355 15.2593 14.6355 14.7958V11.4372C14.6355 10.9737 14.2593 10.5976 13.7958 10.5976C13.3323 10.5976 12.9562 10.9737 12.9562 11.4372Z" fill="#72738C"/>
                <path d="M23.8715 27.9502C25.2626 27.9502 26.3904 26.8224 26.3904 25.4313C26.3904 24.0401 25.2626 22.9124 23.8715 22.9124C22.4803 22.9124 21.3525 24.0401 21.3525 25.4313C21.3525 26.8224 22.4803 27.9502 23.8715 27.9502Z" fill="#72738C"/>
                <path d="M13.2362 27.9502C14.6274 27.9502 15.7551 26.8224 15.7551 25.4313C15.7551 24.0401 14.6274 22.9124 13.2362 22.9124C11.845 22.9124 10.7173 24.0401 10.7173 25.4313C10.7173 26.8224 11.845 27.9502 13.2362 27.9502Z" fill="#72738C"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.83964 6.67928C4.37616 6.67928 4 6.30312 4 5.83964C4 5.37616 4.37616 5 4.83964 5H7.30035C8.21836 5 9.01322 5.63813 9.21249 6.53374L11.9867 19.0175C12.1288 19.6579 12.6976 20.1135 13.3536 20.1135H24.991C25.4545 20.1135 25.8307 20.4897 25.8307 20.9532C25.8307 21.4167 25.4545 21.7928 24.991 21.7928H13.3536C11.9105 21.7928 10.6612 20.7909 10.3477 19.3825L7.57351 6.89871C7.54552 6.76996 7.43133 6.67928 7.30035 6.67928H4.83964Z" fill="#72738C"/>
                </svg>
                <div class="desc">
                  <b>{{ $t('Orders') }}</b>
                </div>
              </router-link>
              <router-link 
                to="/profile/transactions" 
                class="item"
              >
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.0936 3H8.79082C7.24936 3 6 4.24936 6 5.79082V24.7135C6 25.7871 6.61584 26.7657 7.58426 27.2299C8.55268 27.6941 9.70064 27.562 10.5379 26.8894L10.9993 26.5192C11.3398 26.2466 11.8235 26.2466 12.164 26.5192L14.4878 28.3853C15.5074 29.204 16.9577 29.2049 17.9791 28.3881L20.3048 26.5276C20.6444 26.2559 21.1263 26.255 21.4658 26.5266L21.9477 26.9108C22.7859 27.5797 23.9329 27.709 24.8994 27.2439C25.8651 26.7787 26.4791 25.801 26.4791 24.7293V11.3697H20.8844C20.1439 11.3697 19.4341 11.0757 18.9113 10.552C18.3875 10.0291 18.0936 9.31935 18.0936 8.57885V3ZM18.7829 23.159L21.5421 20.3998C21.8082 20.1338 21.8882 19.7338 21.744 19.3858C21.5998 19.0389 21.2602 18.8119 20.8844 18.8119H11.5816C11.0681 18.8119 10.6514 19.2286 10.6514 19.7421C10.6514 20.2557 11.0681 20.6724 11.5816 20.6724H18.6387L17.4675 21.8436C17.1037 22.2064 17.1037 22.7962 17.4675 23.159C17.8303 23.5228 18.4201 23.5228 18.7829 23.159ZM13.6831 12.6041L10.9239 15.3633C10.6579 15.6294 10.5779 16.0294 10.7221 16.3773C10.8663 16.7243 11.2058 16.9513 11.5816 16.9513H20.8844C21.3979 16.9513 21.8147 16.5346 21.8147 16.021C21.8147 15.5075 21.3979 15.0908 20.8844 15.0908H13.8273L14.9985 13.9196C15.3623 13.5567 15.3623 12.9669 14.9985 12.6041C14.6357 12.2404 14.0459 12.2404 13.6831 12.6041ZM19.9541 3.11163V8.57885C19.9541 8.82537 20.0518 9.06259 20.2267 9.23655C20.4006 9.41145 20.6379 9.50912 20.8844 9.50912H26.3702C26.24 9.05701 25.9963 8.64118 25.6567 8.30442L21.1411 3.81213C20.8072 3.48002 20.3979 3.24094 19.9541 3.11163Z" fill="#72738C"/>
                </svg>
                <div class="desc">
                  <b>{{ $t('Transactions') }}</b>
                </div>
              </router-link>
              <div 
                class="item" 
                @click="$parent.logout"
              >
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 19C13.346 19 12 17.654 12 16C12 14.346 13.346 13 15 13H20V6.75C20 5.233 18.767 4 17.25 4H6.75C5.233 4 4 5.233 4 6.75V25.25C4 26.767 5.233 28 6.75 28H17.25C18.767 28 20 26.767 20 25.25V19H15Z" fill="#72738C"/>
                <path d="M22.6168 20.9238C22.2439 20.7688 21.9999 20.4038 21.9999 19.9998V17H15C14.448 17 14 16.552 14 16C14 15.448 14.448 15 15 15H21.9999V12.0002C21.9999 11.5962 22.2439 11.2312 22.6168 11.0762C22.9908 10.9212 23.4208 11.0072 23.7068 11.2932L27.7068 15.293C28.0977 15.684 28.0977 16.316 27.7068 16.707L23.7068 20.7068C23.4208 20.9928 22.9908 21.0788 22.6168 20.9238Z" fill="#72738C"/>
                </svg>
                <div class="desc">
                  <b>{{ $t('Log out') }}</b>
                </div>
              </div>
            </div>
          </div>
          <div 
            v-if="$route.path == '/profile/settings'"
            class="profile-content profile-content-setings"
          >
            <div class="wrapper">
              <div class="profile-content-left">
                <div class="info">
                  <img 
                    src="./../image/avatar.svg" 
                    class="img"
                  >
                  <div class="text">
                    <div class="desc">
                      <b>{{ name }} {{ surname }}</b>
                    </div>
                    <div 
                      class="desc" 
                      v-text="email"
                    />
                  </div>
                </div>
                <div class="top-up-block">
                  <div class="desc main-desc">
                    <b>{{ $t('My balance') }}</b>
                  </div>
                  <div class="balance">
                    <div class="balance-desc">
                      <img 
                        src="./../image/wallet.svg" 
                        class="img"
                      >
                      <div class="desc">
                        <b>
                          <span class="currency">{{ $parent.currency }}</span>
                          {{ balance }}
                        </b>
                      </div>
                    </div>
                    <button 
                      class="button orange" 
                      @click="$parent.openTopup"
                    >
                      {{ $t('Add funds') }}
                    </button>
                  </div>
                </div>
                <div class="form">
                  <div class="desc main-desc">
                    <b>{{ $t('Change password') }}</b>
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Current password') }}
                    </div>
                    <input 
                      v-model="curPass"
                      type="text" 
                      placeholder="Current password" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('New password') }}
                    </div>
                    <input  
                      v-model="newPass"
                      type="text" 
                      placeholder="New password"
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Confirm new password') }}
                    </div>
                    <input 
                      v-model="repeatPass"
                      type="text" 
                      placeholder="Confirm password" 
                    >
                  </div>
                  <button 
                    class="button orange" 
                    @click="updatePassword"
                  >
                    <span>{{ $t('Save') }}</span>
                  </button>
                  <div class="errors">
                    <transition name="fade">
                      <div 
                        v-if="passUpdateErrorMessaage"
                        class="desc red"
                      >
                        {{ $t(passUpdateErrorMessaage) }}
                      </div>
                    </transition>
                    <transition name="fade">
                      <div 
                        v-if="passUpdateSuccessMessaage"
                        class="desc green"
                      >
                        {{ $t(passUpdateSuccessMessaage) }}
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
              <div class="profile-content-right">
                <div class="form">
                  <div class="desc main-desc">
                    <b>{{ $t('Personal Information') }}</b>
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('First name') }}
                    </div>
                    <input 
                      v-model="name"
                      type="text" 
                      placeholder="First name" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Last name') }}
                    </div>
                    <input 
                      v-model="surname"
                      type="text" 
                      placeholder="Last name" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('E-mail') }}
                    </div>
                    <input
                      v-model="email"
                      type="email" 
                      placeholder="E-mail" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Phone') }}
                    </div>
                    <input 
                      v-model="phone"
                      type="tel" 
                      placeholder="Phone" 
                    >
                  </div>
                </div>
                <div class="form">
                  <div class="desc main-desc">
                    <b>{{ $t('Billing address') }}</b>
                  </div>
                  <div 
                    v-if="countryOptions"
                    class="input-container" 
                  >
                    <div class="desc">
                      {{ $t('Country') }}
                    </div>
                    <select v-model="selectedCountry">
                      <option 
                        value="" 
                        disabled 
                        selected
                      >
                        {{ $t('Countries') }}  
                      </option>
                      <option 
                        v-for="item in countryOptions" 
                        :key="item.id"
                        :value="item"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('City') }}
                    </div>
                    <input 
                      v-model="city"
                      type="text" 
                      placeholder="City" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('ZIP Code') }}
                    </div>
                    <input 
                      v-model="zipCode"
                      type="text" 
                      placeholder="ZIP Code" 
                    >
                  </div>
                  <div class="input-container">
                    <div class="desc">
                      {{ $t('Address') }}
                    </div>
                    <input 
                      v-model="address"
                      type="text" 
                      placeholder="Address" 
                    >
                  </div>
                  <button 
                    class="button orange" 
                    @click="updateProfileData"
                  >
                    <span>
                      {{ $t('Save') }}
                    </span>
                  </button>
                  <div class="errors">
                    <transition name="fade">
                      <div 
                        v-if="profileUpdateErrorMessaage"
                        class="desc red" 
                      >
                        {{ $t(profileUpdateErrorMessaage) }}
                      </div>
                    </transition>
                    <transition name="fade">
                      <div 
                        v-if="profileUpdateSuccessMessaage"
                        class="desc green" 
                      >
                        {{ $t(profileUpdateSuccessMessaage) }}
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div 
            v-if="$route.path == '/profile/transactions'"
            class="profile-content profile-content-setings profile-content-transactions"
          >
            <div class="wrapper">
              <div class="desc main-desc">
                <b>{{ $t('Payment hystory') }}</b>
              </div>
              <div 
                v-if="$parent.depositHistory"
                class="profile-table" 
              >
                <div class="profile-table-wrapper profile-form-wrapper">
                  <div class="table-container">
                    <table>
                      <thead>
                        <tr>
                          <td>ID</td>
                          <td>{{ $t('Date') }}</td>
                          <td>{{ $t('Type') }}</td>
                          <td>{{ $t('Status') }}</td>
                          <td>{{ $t('Total') }}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr 
                          v-for="(item,i) in $parent.depositHistory" 
                          :key="i"
                        >
                          <td>{{ item.item_id }}</td>
                          <td>{{ $parent.formatDate(item.created_at) }}</td>
                          <td>{{ $t('Add funds') }}</td>
                          <td 
                            v-if="item.status == 'Accepted'" 
                            class="green"
                          >
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td 
                            v-else-if="item.status == 'Declined'" 
                            class="red"
                          >
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td v-else>
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td class="desc">
                            <b>
                              {{ $parent.currency }} {{ item.sum }}
                            </b>  
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div 
                    id="pagination" 
                    class="pagination" 
                  />
                </div>
              </div>
            </div>
          </div>
          <div 
            v-if="$route.path == '/profile/orders'"
            class="profile-content profile-content-setings profile-content-orders"
          >
            <div class="wrapper">
              <div class="desc main-desc">
                <b>{{ $t('Order history') }}</b>
              </div>
              <div 
                v-if="$parent.orderHistory" 
                class="profile-table" 
              >
                <div class="profile-table-wrapper profile-form-wrapper">
                  <div class="table-container">
                    <table>
                      <thead>
                        <tr>
                          <td>ID</td>
                          <td>{{ $t('Date') }}</td>
                          <td>{{ $t('Skin name') }}</td>
                          <td>{{ $t('Count') }}</td>
                          <td>{{ $t('Status') }}</td>
                          <td>{{ $t('Total') }}</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr 
                          v-for="(item,i) in $parent.orderHistory" 
                          :key="i"
                        >
                          <td>{{ item.item_id }}</td>
                          <td>{{ $parent.formatDate(item.created_at) }}</td>
                          <td>
                            <img 
                              :src="$parent.imgDomain + item.img_url" 
                              class="img"
                            >
                            {{ item.title }}
                          </td>
                          <td class="desc">
                            {{ item.count }}
                          </td>
                          <td 
                            v-if="item.status == 'Accepted'" 
                            class="green"
                          >
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td 
                            v-else-if="item.status == 'Declined'" 
                            class="red"
                          >
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td v-else>
                            <span>{{ $t(item.status) }}</span>
                          </td>
                          <td class="desc">
                            <b>
                              {{ $parent.currency }} {{ item.sum }}
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div 
                    id="pagination"
                    class="pagination" 
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'Profile',
  props: ['appBalance'],
  data: function() {
    return {
      name: '',
      surname: '',
      email: '',
      phone: '',
      country: '',
      selectedCountry: '',
      countryId: '',
      city: '',
      address: '',
      zipCode: '',
      profileUpdateSuccessMessaage: '',
      profileUpdateErrorMessaage: '',
      curPass: '',
      newPass: '',
      repeatPass: '',
      passUpdateSuccessMessaage: '',
      passUpdateErrorMessaage: '',
      balance: '0.00',
      countryOptions: []
    }
  },
  watch: {
    appBalance() {
      this.getProfile();
		},
  },
  mounted() {
    this.getCounties();
  },
  methods: {
    getCounties() {
      this.$http.get(process.env.VUE_APP_API + 'countries')
      .then((res) => {
          this.countryOptions = res.data.payload;
          this.getProfile();
      })
      .catch(() => {
      })
    },
  
    getProfile() {
      this.$http.get(process.env.VUE_APP_API + "user/profile?currency=" + this.$parent.currencyCode)
      .then((res) => {
          this.email = res.data.email;
          this.name = res.data.name;
          this.surname = res.data.surname;
          this.phone = res.data.phone;
          this.balance = res.data.balance;
          this.$emit('setAppBalance', res.data.balance);
          this.city = res.data.city;
          this.zipCode = res.data.zip;
          this.address = res.data.address;
          let countryId = this.countryOptions.find(obj => obj.id == res.data.country);
          this.selectedCountry = countryId;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
      })
    },
    updateProfileData() {
      let profileData = {
        "email": this.email,
        "name": this.name,
        "surname": this.surname,
        "phone": this.phone,
        "country": this.selectedCountry.id,
        "city": this.city,
        "postCode": this.zipCode,
        "address": this.address
      }
      this.$http.put(process.env.VUE_APP_API + 'user/profile', profileData)
      .then((res) => {
        if (res.data.status == "OK") {
          this.profileUpdateSuccessMessaage = 'Success';
          setTimeout(() => {
            this.profileUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.profileUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.profileUpdateErrorMessaage = '';
        }, 3000);
      })
    },
    updatePassword() {
      let passwordList = {
        "cur_password": this.curPass,
        "new_password": this.newPass,
        "repeat_password": this.repeatPass
      }
      this.$http.post(process.env.VUE_APP_API + 'user/change-password', passwordList)
      .then((res) => {
        if (res.data.status == "OK") {
          this.passUpdateSuccessMessaage = 'Success';
          this.curPass = '',
          this.newPass = '',
          this.repeatPass = '';
          setTimeout(() => {
            this.passUpdateSuccessMessaage = '';
          }, 3000);
        }
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignIn();
        }
        this.passUpdateErrorMessaage = res.response.data.message;
        setTimeout(() => {
          this.passUpdateErrorMessaage = '';
        }, 3000);
      })
    }
    
  }
}
</script>

