<template>
  <main class="main page-inside payment-result-page">
    <div class="main-wrapper">
      <div class="title title-top">
        <img src="./../image/hero.jpg" class="bg"/>
        <b>{{ $t('Success') }}</b>
      </div>
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title big">
            {{ $t('Thank you - your payment was successful. You will shortly receive a confirmation to your e-mail. In case of any questions, please contact us at talk@') }}{{$parent.hostname}}
        </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'PaymentSuccessPage',
  props: [],
  components: {
  },
  data: function() {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>