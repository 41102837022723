<template>
  <main class="main page-inside text-page contact-us-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="wrapper">
          <div class="title">
            <span>Contact us</span>
          </div>
          <div class="content form-fields-wrapper">
            <div class="title">Leave your feedback</div>
            <div class="fields">
              <label class="first">
                <div class="desc">Name:</div>
                <input type="text" placeholder="Name" v-model="name"/>
              </label>
              <label class="second">
                <div class="desc">Email:</div>
                <input type="text" placeholder="Email" v-model="email"/>
              </label>
              <label class="last">
                <div class="desc">Message</div>
                <textarea placeholder="Message" v-model="message"></textarea>
              </label>
              <button class="button red long" @click="submit">Send</button>
              <transition name="fade">
                <div v-if="$parent.successMessage" class="desc small green">{{ $t($parent.successMessage) }}</div>
              </transition>
              <transition name="slide">
                <div v-if="$parent.error" class="error-desc desc red">{{ $t($parent.error) }}</div>
              </transition>
            </div>
          </div>
          <div class="info">
            <div class="desc small">For all your general inquiries and support needs</div>
            <div class="link-container">
              <span class="desc small link" v-text="$parent.footerSupportEmail" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'ContactUs',
  props: [],
  components: {
    
  },
  data: function() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  methods: {
    submit() {
      let data = {
        "name": this.name,
        "email": this.email,
        "message": this.message
      }
      this.$emit('sendContactForm', data);
    },
  }
}
</script>